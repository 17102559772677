import React, {useState} from 'react';
import './App.css';

function Home() {
  return (
    <Tour></Tour>
  )
}

function Tour() {
  return (<div class="inner">

      <h2>tour schedule<strong> :: </strong>2023</h2>
      <p><i>updated 6.19.23 (subject to change)</i></p>
      <ul>
        <li><strong>1/1</strong> <a class="strike" href="https://www.pdga.com/tour/event/65504" target="_blank">PPFDC 1st TAGS OF THE YEAR // Colorado Springs, CO // C-Tier</a> <strong>1st</strong></li>
        <li><strong>2/11</strong> <a class="strike" href="https://www.pdga.com/tour/event/66324" target="_blank">SOUTH CITY SHOWDOWN // St Louis, MO // B-Tier</a> <strong>2nd</strong></li>
        <li><strong>3/3-5</strong> <a class="strike" href="https://www.pdga.com/tour/event/65723" target="_blank">LINCOLN COUNTY WAR // Ruidoso, NM // B-Tier</a> <strong>3rd</strong></li>        
        <li><strong>3/15</strong> <a class="strike" href="https://www.pdga.com/tour/event/66498" target="_blank">FUEGO FLEX // Austin, TX // C-Tier</a> <strong>T-14th</strong></li>
        <li><strong>3/24-26</strong> <a class="strike" href="https://www.pdga.com/tour/event/66182" target="_blank">TEXAS STATES // Houston, TX // S-Tier</a> <strong>T-60th</strong></li>
        <li><strong>4/1</strong> <a class="strike" href="https://www.pdga.com/tour/event/65944" target="_blank">AVIARY FOOLS // Colorado Springs, CO // B-Tier</a> <strong>T-3rd</strong></li>
        <li><strong>4/8</strong> <a class="strike" href="https://www.pdga.com/tour/event/65457" target="_blank">THE PUEBLO FLEX // Pueblo, CO // C-Tier</a> <strong>T-22nd</strong></li>
        <li><strong>4/29-30</strong> <a class="strike" href="https://www.pdga.com/tour/event/66573" target="_blank">WIDEFIELD OPEN // Colorado Springs, CO // B-Tier</a> <strong>T-5th</strong></li>
        <li><strong>5/5-7</strong> <a class="strike" href="https://www.pdga.com/tour/event/64597" target="_blank">303 OPEN // Fort Collins, CO // A-Tier</a> <strong>T-17th</strong></li>
        <li><strong>5/19-21</strong> <a class="strike" href="https://www.pdga.com/tour/event/66525" target="_blank">BEAVER STATE FLING // Estacada, OR // S-Tier</a> <strong>T-96th</strong></li>
        <li><strong>6/17-18</strong> <a class="strike" href="https://www.pdga.com/tour/event/69142" target="_blank">BATTLE ON THE FRONT RANGE // Colorado Springs, CO // B-Tier</a> <strong>2nd</strong></li>
        <li><strong>6/24-25</strong> <a class="" href="https://www.pdga.com/tour/event/69142" target="_blank">SOLSTICE SOIREE // Red Feather Lakes, CO // B-Tier</a></li>
        <li><strong>7/7-9</strong> <a class="" href="https://www.pdga.com/tour/event/66098" target="_blank">KC WIDE OPEN // Kansas City, MO // S-Tier</a></li>
        <li><strong>8/30-9/3</strong> <a class="" href="https://www.pdga.com" target="_blank">WORLDS // Jeffersonville, VT // Major</a></li>
        <li><strong>9/22-24</strong> <a class="" href="https://www.pdga.com/tour/event/64599" target="_blank">COLORADO STATE CHAMPIONSHIPS // Denver, CO // A-Tier</a></li>
        <li><strong>12/8-10</strong> <a class="" href="https://www.pdga.com/tour/event/65002" target="_blank">SOUTH FLORIDA OPEN // Fort Lauderdale, FL // A-Tier</a></li>
        </ul>

            <h2><span class="text-white">"</span>tour<span class="text-white">"</span> schedule<strong> :: </strong>2022</h2>
    <ul>
      <li><strong>1/16</strong> <a class="strike" href="https://www.pdga.com/tour/event/56391#MPO" target="_blank">J719 WIDEFIELD FLEX // Colorado Springs, CO // C-Tier</a> <strong>T-1st</strong></li>
      <li><strong>2/12</strong> <a class="strike" href="" target="_blank">VETERANS AT WIDEFIELD // Colorado Springs, CO // Unsanctioned</a> <strong>T-1st</strong></li>
      <li><strong>2/24-27</strong> <a class="strike" href="https://www.pdga.com/tour/event/55580#MPO" target="_blank">LAS VEGAS CHALLENGE // Henderson, NV // E-Tier</a> <strong>T-57th</strong></li>
      <li><strong>3/12</strong> <a class="strike" href="https://www.pdga.com/tour/event/56675#MPO" target="_blank">J719 PUEBLO FLEX // Pueblo, CO // C-Tier</a> <strong>1st</strong></li>
      <li><strong>3/14</strong> <a class="strike" href="https://www.pdga.com/tour/event/56992#MPO" target="_blank">FLYGREEN FLEX 1 // Castle Rock, CO // C-Tier</a> <strong>T-2nd</strong></li>
      <li><strong>3/27</strong> <a class="strike" href="https://www.pdga.com/tour/event/55370#MPO" target="_blank">WINTER FLING // Arvada, CO // C-Tier</a> <strong>3rd</strong></li>
      <li><strong>4/2</strong> <a class="strike" href="https://www.pdga.com/tour/event/56665#MPO" target="_blank">AVIARY FOOLS // Colorado Springs, CO // C-Tier</a> <strong>1st</strong></li>
      <li><strong>4/4</strong> <a class="strike" href="https://www.pdga.com/tour/event/58510#MPO" target="_blank">FLYGREEN FLEX 2 // Village Greens, CO // C-Tier</a> <strong>T-7th</strong></li>
      <li><strong>4/10</strong> <a class="strike" href="https://www.pdga.com/tour/event/57341#MPO" target="_blank">J719 TWISTED CEDARS FLEX // Trinidad, CO // C-Tier</a> <strong>2nd</strong></li>
      <li><strong>4/30</strong> <a class="strike" href="https://www.pdga.com/tour/event/57910" target="_blank">CHASIN THE CHAINS // Colorado Springs, CO // C-Tier</a> <strong>2nd</strong></li>
      <li><strong>5/6-8</strong> <a class="strike" href="https://www.pdga.com/tour/event/56406" target="_blank">THE 303 OPEN // Denver, CO // A-Tier</a> <strong>T-11th</strong></li>
      <li><strong>5/14-15</strong> <a class="strike" href="https://www.pdga.com/tour/event/56404" target="_blank">WIDEFIELD OPEN // Colorado Springs, CO // B-Tier</a> <strong>T-12th</strong></li>
      <li><strong>5/21-22</strong> <a class="strike" href="https://www.pdga.com/tour/event/59122" target="_blank">HOW THE WEST WAS WON // Parker, CO // B-Tier</a> <strong>2nd</strong></li>
      <li><strong>5/29</strong> <a class="strike" href="https://www.pdga.com/tour/event/58534" target="_blank">SPRING FLING // Arvada, CO // B-Tier</a> <strong>T-6th</strong></li>
      <li><strong>6/17-19</strong> <a class="strike" href="https://www.pdga.com/tour/event/55199" target="_blank">HIGH PLAINS CHALLENGE // Fort Morgan, CO // A-Tier</a> <strong>5th</strong></li>
      <li><strong>6/26</strong> <a class="strike" href="https://www.discgolfscene.com/tournaments/Ballz_Chainz_2022" target="_blank">BALLZ CHAINZ - 4 MAN // Woodland Park, CO // Unsanctioned</a> <strong>4th</strong></li>
      <li><strong>7/11</strong> <a class="strike" href="https://www.pdga.com/tour/event/60532" target="_blank">FLYGREEN FLEX 5 // Greenwood Village, CO // C-Tier</a> <strong>3rd</strong></li>
      <li><strong>7/29-31</strong> <a class="strike" href="https://www.pdga.com/tour/event/58722" target="_blank">THE SIZZLER // Junction, TX // B-Tier</a> <strong>1st</strong></li>
      <li><strong>8/7</strong> <a class="strike" href="https://www.pdga.com/tour/event/59127" target="_blank">DRAGON DISCDOWN // Aurora, CO // B-Tier</a> <strong>T-6th</strong></li>
      <li><strong>8/25</strong> <a class="strike" href="https://www.pdga.com/tour/event/56891" target="_blank">KANSAS CITY WIDE OPEN // Kansas City, KS // A-Tier</a> <strong>12th</strong></li>
      <li><strong>9/10-11</strong> <a class="strike" href="https://www.pdga.com/tour/event/60062" target="_blank">CLASH AT THE CREEK // Colorado Springs, CO // B-Tier</a> <strong>3rd</strong></li>
      <li><strong>9/23-25</strong> <a class="strike" href="https://www.pdga.com/tour/event/57156" target="_blank">COLORADO STATE CHAMPIONSHIPS // Colorado Springs, CO // A-Tier</a> <strong>T-10th</strong></li>
      <li><strong>10/7-9</strong> <a class="strike" href="https://www.pdga.com/tour/event/55261" target="_blank">THE HARVEST // Bailey, CO // B-Tier</a> <strong>5th</strong></li>
      <li><strong>11/25</strong> <a class="strike" href="https://www.pdga.com/tour/event/64011" target="_blank">CODGHOF Winter Series Opening Stop // Fort Collins, CO // C-Tier</a> <strong>11th</strong></li>
      <li><strong>12/17</strong> <a class="strike" href="https://www.pdga.com/tour/event/63665" target="_blank">THE FLEX BEFORE CHRISTMAS // Colorado Springs, CO // C-Tier</a> <strong>T-4th</strong></li>
      </ul>
  </div>)
}

function Bag() {
  return (
    <div>
      <h2>bag<strong> :: </strong>current snapshot</h2>
      <ul>
        <li>5 gateway wizard (g9i, ss)</li>
        <li>1 gateway doggy style (superglow)</li>
        <li>1 reptilian scale (fossil)</li>
        <li>1 gateway houdini (nylon)</li>
        <li>1 gateway demon (older run)</li>
        <li>1 gateway sabre (evolution)</li>
        <li>2 gateway blade v2 (hd)</li>
        <li>1 gateway assassin (diamond)</li>
        <li>2 gateway diablo (diamond)</li>
        <li>1 gateway spell</li>
        <li>2 gateway g1 (proto, diamond)</li>
      </ul>
      <h4>in the bag video (q2 2023)</h4>
      <iframe width="420" height="315" src="https://www.youtube.com/embed/Ao-zbWVcmSA"></iframe>
    </div>)
}

function Shop() {
  return (<div class="inner">
    <h2>shop</h2>
    <p>i'm working on putting together a little store, to help support getting me out to some of these events, stay tuned!</p>
  </div>)
}

function Sponsors() {
  return (<div>
    <h2>sponsors</h2>
    <ul>
      <li><a href="https://gatewaydiscsports.com" target="_blank">Gateway Disc Sports</a></li>
      <li><a href="https://www.facebook.com/vibe.discgolf.7" target="_blank">Vibe DiscGolf</a></li>
    </ul>
  </div>)
}

function Media() {
  return (
  <div class="inner">
    <h2>my socials</h2>
    <a
          href="https://www.youtube.com/channel/UCwg-uDlmjxOJzxXf6GUiwig"
          target="_blank"
          rel="noreferrer noopener">
          YouTube
    </a>
    <a
          href="https://instagram.com/bstet"
          target="_blank"
          rel="noreferrer noopener">
          Instagram
    </a>
    <a
          href="https://tiktok.com/@m1erze"
          target="_blank"
          rel="noreferrer noopener">
          TikTok
    </a>
    <h4>below are my features in various media coverages. many have a second half but just linking the front portion here.</h4>
    <h2>2022</h2>
    <iframe width="420" height="315" src="https://youtube.com/embed/YSeJDA3MXVU"></iframe>
    <h2>2019</h2>
    <iframe width="420" height="315" src="https://www.youtube.com/embed/4H5wsQlbxUw"></iframe>
    <iframe width="420" height="315" src="https://www.youtube.com/embed/6ENkXRZPszE"></iframe>
    <iframe width="420" height="315" src="https://www.youtube.com/embed/Jj6vE8zCONA"></iframe>
    <iframe width="420" height="315" src="https://www.youtube.com/embed/X0uloeQ6Nms"></iframe>
    <h2>2016</h2>
    <iframe width="420" height="315" src="https://www.youtube.com/embed/wcm-yedtXs8"></iframe>
    <iframe width="420" height="315" src="https://www.youtube.com/embed/4Qosblt19hw"></iframe>
    <iframe width="420" height="315" src="https://www.youtube.com/embed/vvvoYVGXiFs"></iframe>
  </div>)
}
// <img class="thumbnail" src="./B.jpeg" alt="Logo" />;
// <img class="thumbnail" src="./B.jpeg" alt="Logo" />;
// <img class="thumbnail" src="./B.jpeg" alt="Logo" />;
// <img class="thumbnail" src="./B.jpeg" alt="Logo" />;
// <img class="thumbnail" src="./B.jpeg" alt="Logo" />;
// <img class="thumbnail" src="./B.jpeg" alt="Logo" />;
// <img class="thumbnail" src="./B.jpeg" alt="Logo" />;
// <img class="thumbnail" src="./B.jpeg" alt="Logo" />;
// <img class="thumbnail" src="./B.jpeg" alt="Logo" />;
//

function About() {
  return (<div class="inner">
    <h2>about me</h2>
    <h4>professional disc golfer <strong>//</strong> software engineer <strong>//</strong> pc gamer (<a href="https://twitch.tv/m1erze" target="_blank">twitch</a>) <strong>//</strong> hobbyist guitarist  <strong>//</strong> washed up volleyballer <strong>//</strong> cat lover</h4>
    <h4>rhode island <strong class="text-large">→</strong> indiana <strong class="text-large">→</strong> south carolina <strong class="text-large">→</strong> georgia / florida <strong class="text-large">→</strong> colorado</h4>
    <Bag></Bag>
    <Sponsors></Sponsors>
  </div>)
}


function App() {
  let [page, setPage] = useState(1);
  function callIt(p) {
    // alert(p)
    setPage(p);
  }
  // <button onClick={callIt(2)}>Tour</button>
  // <button onClick={callIt(3)}>Shop</button>
  //   <img class="header-gif" src="brett.gif" alt="brett"/>
  // <button onClick={callIt(5)}>Thanks</button>
  return (
    <div class="main">
      <header class="nav-btns">
        <h1 class="text-white-black">Brett Mierzejewski Stetson</h1>
        <h4 class=""><a href="https://gatewaydiscsports.com" target="_blank">Team Gateway Disc Sports</a> :: <a target="_blank" href="https://pdga.com/player/79063">PDGA #79063</a></h4>
        <div class="header-gif-div"></div>
        
        <button onClick={() => callIt(1)}>Home</button>
        <button onClick={() => callIt(2)}>About</button>
        <button onClick={() => callIt(4)}>Media</button>
        <button onClick={() => {window.location.href="https://brettstet.myshopify.com"}}>Shop</button>
    </header>
    { page == 1 && (<>
      <Home></Home></>
    ) }
    { page == 2 && (<>
      <About></About></>
    ) }
    { page == 3 && (<Shop></Shop>) }
    { page == 4 && (<Media></Media>) }

    <footer>
        <a
          href="https://www.youtube.com/channel/UCwg-uDlmjxOJzxXf6GUiwig"
          target="_blank"
          rel="noreferrer noopener">
          Follow me on YouTube!
        </a>
        <a href="mailto:mierze@gmail.com">Shoot me an email mierze@gmail.com</a>
    </footer>
  </div>
  );
}
// <button onClick={() => callIt(2)}>Tour</button>
// <button onClick={() => callIt(4)}>About</button>

export default App;
